<template>
    <div class="container-fluid">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header d-flex justify-content-between">
                    <div v-if="!currentProject" class="header-title">
                        <h4 class="card-title">Gestão de tarefas - lista de projetos</h4>
                    </div>
                    <div v-else class="header-title d-flex justify-content-between flex-grow-1">
                        <h4 class="card-title">Gestão de tarefas - projeto '{{currentProject.title}}'</h4>
                        <h5 class="cursor-pointer font-weight-bold" @click="closeProject">X</h5>
                    </div>
                </div>
                <!-- Projects -->
                <div v-if="!currentProject" class="card-body">
                    <div class="column">
                        <!-- Add project  -->
                        <div class="row m-2 p-1 justify-content-end">
                            <div v-if="showAdd" class="row flex-grow-1" style="gap:1rem">
                                <input type="text" v-model="projectName" placeholder="Novo projeto"/>
                            </div>
                            <div class="row justify-content-end">
                                <a v-if="!showAdd" class="bg-primary p-1" @click="showAdd = true" href="#">Adicionar</a>
                                <a v-else :class="{'disabled': !projectName}" class="bg-primary p-1" @click="addProject" href="#">Confirmar</a>
                            </div>
                        </div>
                        <!-- Board -->
                        <div style="height: 500px">
                            <vue-cal locale="pt-br" ref="vuecal" :events="projects" :time="false" hide-weekends hide-view-selector hide-title-bar small :cell-click-hold="true" :editable-events="true"  @event-delete="removeItemRequested($event)" activeView="week" @ready="viewChanged" @view-change="viewChanged" :resize-x="false" :multiple-day="false" :on-event-click="onProjectClick"/>
                        </div>
                    </div>
                </div>
                <!-- Tasks -->
                <div v-else class="card-body">
                    <div class="column">
                        <!-- Add task  -->
                        <div class="row m-2 p-1 justify-content-end">
                            <div v-if="showAdd" class="row flex-grow-1" style="gap:1rem">
                                <select v-model="resources" multiple>
                                    <option selected disabled>Escolher recursos humanos</option>
                                    <option v-for="resource in humanResources" :key="resource.email" :value="resource">{{resource["Nome"]}}</option>
                                </select>
                                <input type="text" v-model="taskName"/>
                            </div>
                            <div class="row justify-content-end">
                                <a v-if="!showAdd" class="bg-primary p-1" @click="showAdd = true" href="#">Adicionar</a>
                                <a v-else :class="{'disabled': !resources || !resources.length || !taskName}" class="bg-primary p-1" @click="addTask" href="#">Confirmar</a>
                            </div>
                        </div>
                        <!-- Board -->
                        <div style="height: 500px">
                            <vue-cal locale="pt-br" ref="vuecal" :events="currentProjectTasks" :time="false" hide-weekends hide-view-selector hide-title-bar small :cell-click-hold="true" :editable-events="true"  @event-delete="removeItemRequested($event)" activeView="week" @ready="viewChanged" @view-change="viewChanged" :resize-x="false" :multiple-day="false"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'

export default {
  components: { VueCal },
  data() {
        return {
            humanResources: [],
            projects: localStorage.getItem('projects') && Boolean(localStorage.getItem('projects')) ? JSON.parse(localStorage.getItem('projects')) : [],
            currentProject: null,
            tasks: localStorage.getItem('tasks') && Boolean(localStorage.getItem('tasks')) ? JSON.parse(localStorage.getItem('tasks')) : [],
            showAdd: false,
            resources: [],
            taskName: null,
            viewStartDate: null,
            projectName: null
        }
   },
    mounted () {
        // Get human resources
        this.readTextFile('https://wilddourocrm.painelgestao.pt/csv/funcionarios.csv');

        // Setup columns
        setTimeout(function(){
            var labels = document.getElementsByClassName("vuecal__flex weekday-label");
            labels[0].innerHTML = "<div class='status-label'>A FAZER</div>";
            labels[1].innerHTML = "<div class='status-label'>PENDENTE</div>";
            labels[2].innerHTML = "<div class='status-label'>EM PROGRESS</div>";
            labels[3].innerHTML = "<div class='status-label'>PARA VALIDAÇÃO</div>";
            labels[4].innerHTML = "<div class='status-label'>FEITO</div>";
        },100)
   },
   computed:
   {
        currentProjectTasks: function()
        {
            var currentProjectId = this.currentProject.id;
            return this.tasks.filter(function( obj ) {
                return obj.project == currentProjectId;
            });
        }
   },
   methods: {
       readTextFile(file) {
           this.humanResources = [];
           let self = this;

           let rawFile = new XMLHttpRequest();
           rawFile.open("GET", file, false);
           rawFile.onreadystatechange = function ()
           {
               if(rawFile.readyState === 4)
               {
                 if(rawFile.status === 200 || rawFile.status == 0)
                 {
                    let csv = rawFile.responseText;
                    let lines = csv.split('\n');

                    // Get structure of object
                    let headers = lines[0].split(";") 

                    for(let line = 1; line < lines.length; line++){
                       let values = lines[line].split(";");
                       let client = {};

                       for (let index = 0; index < values.length - 1; index++) {
                          let key = headers[index];
                          client[key] = values[index];
                       }
                       
                       self.humanResources.push(client);
                    }        
                 }
               }
           }
           rawFile.send(null);
       },
       addTask()
       {
            var id = new Date().getTime();
            var title = `${this.taskName} (${this.resources.map(x=> x["Nome"]).join(",")})`;

            this.tasks.push({id: id, resources: this.resources, title: title, start: this.viewStartDate, end: this.viewStartDate, allDay: true, delete: true, project: this.currentProject.id});

            this.resources = [];
            this.taskName = null;
            this.showAdd = false;
       },
       removeItemRequested(event)
       {
            this.tasks = this.tasks.filter(function( obj ) {
                return obj.id !== event.id;
            });
       },
       viewChanged(params)
       {
            this.viewStartDate = new Date(params.startDate).toUTCString();
       },
       addProject()
       {
            var id = new Date().getTime();
            this.projects.push({id: id, title: this.projectName, start: this.viewStartDate, end: this.viewStartDate, allDay: true, delete: true});

            this.projectName = null;
            this.showAdd = false;
       },
       onProjectClick(event)
       {
            this.currentProject = event;
            this.showAdd = false;
       },
       closeProject()
       {
            this.resources = [];
            this.taskName = null;
            this.showAdd = false;
            this.currentProject = null;
       }
    },
    watch:
    {
        tasks: function()
        {
            localStorage.setItem('tasks', JSON.stringify(this.tasks));
        },
        projects: function()
        {
            localStorage.setItem('projects', JSON.stringify(this.projects));
        }
    }
}
</script>

<style scoped>
>>>.vuecal__event.vuecal__event--all-day {
    padding: 1rem 0;
    cursor: pointer;
    background-color: white;
    margin-bottom: 1rem;
}

>>>.vuecal__no-event
{
    display: none;
}

.cursor-pointer
{
    cursor: pointer;
}

.font-weight-bold
{
    font-weight: bold;
}
</style>